import { AmplitudeClient } from 'amplitude-js';
import { getCookieValue } from '@config/axeptio';
import { getEnvVar } from '@config/configEnv';
import { TRACKING_COOKIE_AMPLITUDE, TRACKING_COOKIE_NAME } from '@utils/consts/cookie';
import { EventProps } from '@utils/types/amplitude/events';
import { UserProperties } from '@utils/types/amplitude/userProperties';

let allowAmplitudeTracking = false;
let amplitudeInstance: AmplitudeClient | null = null;

// Amplitude relies on the window object and should only be run in the browser (not server side)
const isBrowser = process.browser;
const AMPLITUDE_API_KEY = getEnvVar('AMPLITUDE_API_KEY');

export const launchAmplitude = async (isLoggedUser: boolean): Promise<void> => {
    // Automatic allow tracking on sign up, after CGU acceptation
    allowAmplitudeTracking = isLoggedUser
        ? true
        : isBrowser && getCookieValue(TRACKING_COOKIE_NAME).includes(TRACKING_COOKIE_AMPLITUDE);
    if (allowAmplitudeTracking) {
        const amplitude = (await import('amplitude-js')).default;
        amplitudeInstance = amplitude.getInstance();
        amplitudeInstance.init(AMPLITUDE_API_KEY);
    }
};

export const logEvent = async ({ name, params }: EventProps): Promise<void> => {
    // we check the initialization of amplitude as the logging can happen too early for the pageview
    if (allowAmplitudeTracking && amplitudeInstance) {
        amplitudeInstance.logEvent(name, params);
    }
};

export const setUserProperties = async (userId: string, props: UserProperties): Promise<void> => {
    if (allowAmplitudeTracking && amplitudeInstance) {
        amplitudeInstance.setUserId(userId);
        amplitudeInstance.setUserProperties(props);
    }
};
