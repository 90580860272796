import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { logEvent as logAmplitudeEvent, setUserProperties } from '@config/amplitude';
import { APP_NAME } from '@utils/consts/livv';
import { DefinedEvents } from '@utils/types/amplitude/events';
import { UserProperties } from '@utils/types/amplitude/userProperties';

interface UseAmplitudeProps {
    logEvent: (eventProps: DefinedEvents) => Promise<void>;
    setUserProperties: (userId: string, props: UserProperties) => void;
}

/**
 * Amplitude hook to track user interactions.
 *
 *  - logEvent: Register specific event by its name and props
 *  - setUserId: When a unique user connects to the app, use its id to associate further events
 *  - setUserProperties: Save props describing your user
 *
 *  You can then retrieve and analyse those data in your Amplitude dashboard:
 *  => https://analytics.amplitude.com/vogel-interactive
 */
const useAmplitude = (): UseAmplitudeProps => {
    const { lang } = useTranslation();
    const router = useRouter();

    const logEvent = useCallback(
        async ({ name, params }: DefinedEvents) => {
            await logAmplitudeEvent({
                name,
                params: {
                    app_name: APP_NAME,
                    language: lang,
                    page_url: router.asPath,
                    ...params,
                },
            });
        },
        [lang, router.asPath],
    );

    return { logEvent, setUserProperties };
};

export default useAmplitude;
