import { LawTheme, ModelType } from '@livv/models';
import { DocumentsToPrint } from 'functions/generateDocumentPdf';

export enum TrackingEventName {
    ADVICE_NOTE_CONTENT_CONSUMED = 'advice_note_content_consumed',
    ANALYSIS_RESULT_CLICKED = 'analysis_result_clicked',
    ANALYSIS_RESULT_SHOW_MORE_CLICKED = 'analysis_result_show_more_clicked',
    ARTICLE_EXTRA_CONTENT_CONSUMED = 'article_extra_content_consumed',
    BODY_EXTRA_CONTENT_CLICKED = 'body_extra_content_clicked',
    CODEARTICLE_OFFICIAL_SOURCE_CLICKED = 'codeArticle_official_source_clicked',
    CONTENT_NAVIGATION_CLICKED = 'content_navigation_clicked',
    DECISION_EXTRA_CONTENT_CONSUMED = 'decision_extra_content_consumed',
    DOCUMENT_READ = 'document_read',
    END_OF_TRIAL_CONTACT_BUTTON_CLICKED = 'end_of_trial_contact_button_clicked',
    FREEMIUM_CARD_CLICKED = 'freemium_card_clicked',
    FREEMIUM_MODAL_OPENED = 'freemium_modal_opened',
    HEADER_QUICK_SEARCH_CLICKED = 'header_quick_search_clicked',
    HEADER_NAVIGATION_CLICKED = 'header_navigation_clicked',
    ICICLE_PAGE_CLICKED = 'icicle_page_clicked',
    ICICLE_REFINED = 'icicle_refined',
    LINKED_ANALYSIS_CLICKED = 'linked_analysis_clicked',
    OFFER_MULTIPLE_LICENCES_BUTTON_CLICKED = 'offer_multiple_licences_button_clicked',
    PAGE_VIEW = 'page_view',
    PAYMENT_FAIL_CONTACT_BUTTON_CLICKED = 'payment_fail_contact_button_clicked',
    PAYMENT_FAIL_RETRY_BUTTON_CLICKED = 'payment_fail_payment_retry_button_clicked',
    PAYMENT_SUCCESS_BACK_TO_LIVV_BUTTON_CLICKED = 'payment_success_back_to_livv_button_clicked',
    PAYWALL_LOGIN_LINK_CLICKED = 'paywall_login_link_clicked',
    PAYWALL_BUTTON_CLICKED = 'paywall_button_clicked',
    PDF_DOWNLOAD = 'pdf_download',
    PURCHASE_BUTTON_CLICKED = 'purchase_button_clicked',
    RESULT_CLICKED = 'result_clicked',
    SALES_APPOINTMENT_BOOKING_BUTTON_CLICKED = 'sales_appointment_booking_button_clicked',
    SEARCH = 'search',
    SEARCH_PAGE_CLICKED = 'search_page_clicked',
    SIDE_EXTRA_CONTENT_CLICKED = 'side_extra_content_clicked',
    SIGNUP_SUCCESS = 'signup_success',
    THEMATIC_PLAN_AREA_SELECTED = 'thematic_plan_area_selected',
    THEMATIC_PLAN_BUSINESS_LAW_CATEGORY_SELECTED = 'thematic_plan_business_law_category_selected',
    THEMATIC_PLAN_COLLAPSE_EXPAND_ALL_BUTTON_CLICKED = 'thematic_plan_collapse_expand_all_button_clicked',
    THEMATIC_PLAN_NODE_CLICKED = 'thematic_plan_node_clicked',
    THEMATIC_PLAN_TABLE_OF_CONTENT_SEARCH_SUBMITTED = 'thematic_plan_table_of_content_search_submitted',
    THEMATIC_PLAN_TREE_NODE_COLLAPSE_EXPAND_BUTTON_CLICKED = 'thematic_plan_tree_node_collapse_expand_button_clicked',
}

export interface EventProps {
    name: TrackingEventName;
    params: Record<string, unknown>;
}

interface DocumentLink {
    target_document_id: string;
    target_document_type: ModelType;
}

interface UsageOrigin {
    logged_user: boolean;
}

interface HeaderQuickSearchClicked {
    name: TrackingEventName.HEADER_QUICK_SEARCH_CLICKED;
    params: UsageOrigin;
}

interface HeaderNavigationClicked {
    name: TrackingEventName.HEADER_NAVIGATION_CLICKED;
    params: {
        item: string;
    };
}

interface AdviceNoteContentConsumed {
    name: TrackingEventName.ADVICE_NOTE_CONTENT_CONSUMED;
    params: DocumentLink;
}

interface AnalysisResultClicked {
    name: TrackingEventName.ANALYSIS_RESULT_CLICKED;
    params: {
        analysis_id: string;
        decision_id: string;
    };
}

interface AnalysisResultShowMoreClicked {
    name: TrackingEventName.ANALYSIS_RESULT_SHOW_MORE_CLICKED;
    params: {
        decision_id: string;
    };
}

interface ArticleExtraContentConsumed {
    name: TrackingEventName.ARTICLE_EXTRA_CONTENT_CONSUMED;
    params: DocumentLink;
}

interface BodyExtraContentClicked {
    name: TrackingEventName.BODY_EXTRA_CONTENT_CLICKED;
    params: DocumentLink;
}

interface CodearticleOfficialSourceClicked {
    name: TrackingEventName.CODEARTICLE_OFFICIAL_SOURCE_CLICKED;
    params?: Record<string, unknown>;
}

export type NavigationDirection = 'next' | 'previous';

interface ContentNavigationClicked {
    name: TrackingEventName.CONTENT_NAVIGATION_CLICKED;
    params: {
        document_type: ModelType;
        navigation_direction: NavigationDirection;
        target_document_id: string;
    };
}

interface DecisionExtraContentConsumed {
    name: TrackingEventName.DECISION_EXTRA_CONTENT_CONSUMED;
    params: DocumentLink;
}

interface EndOfTrialContactButtonClicked {
    name: TrackingEventName.END_OF_TRIAL_CONTACT_BUTTON_CLICKED;
    params?: Record<string, unknown>;
}

interface PdfDownload {
    name: TrackingEventName.PDF_DOWNLOAD;
    params: {
        documents: DocumentsToPrint[];
    };
}

interface DocumentRead {
    name: TrackingEventName.DOCUMENT_READ;
    params: {
        reading_state: 'half' | 'full' | 'minute';
        screen_name: string;
    };
}

interface FreemiumCardClicked {
    name: TrackingEventName.FREEMIUM_CARD_CLICKED;
    params: {
        freemium_type_key: string;
    };
}

interface FreemiumModalOpened {
    name: TrackingEventName.FREEMIUM_MODAL_OPENED;
    params: {
        type_freemium_modal: string;
    };
}

interface SignupSuccess {
    name: TrackingEventName.SIGNUP_SUCCESS;
    params?: Record<string, unknown>;
}

interface IcicleRefined {
    name: TrackingEventName.ICICLE_REFINED;
    params: {
        label: string;
    };
}

interface LinkedAnalysisClicked {
    name: TrackingEventName.LINKED_ANALYSIS_CLICKED;
    params: {
        source: 'button' | 'breadcrumbs';
    };
}

interface OfferMultipleLicencesButtonClicked {
    name: TrackingEventName.OFFER_MULTIPLE_LICENCES_BUTTON_CLICKED;
    params: {
        trial_expired: boolean;
    };
}

interface PageView {
    name: TrackingEventName.PAGE_VIEW;
    params: {
        logged_user: boolean;
        screen_name: string;
    };
}

interface PaymentFailContactButtonClicked {
    name: TrackingEventName.PAYMENT_FAIL_CONTACT_BUTTON_CLICKED;
    params?: Record<string, unknown>;
}

interface PaymentFailRetryButtonClicked {
    name: TrackingEventName.PAYMENT_FAIL_RETRY_BUTTON_CLICKED;
    params?: Record<string, unknown>;
}

interface PaymentSuccessBackButtonClicked {
    name: TrackingEventName.PAYMENT_SUCCESS_BACK_TO_LIVV_BUTTON_CLICKED;
    params?: Record<string, unknown>;
}

interface PaywallButtonClicked {
    name: TrackingEventName.PAYWALL_BUTTON_CLICKED;
    params: {
        action: 'contact_support_livv' | 'signup';
    };
}

interface PaywallLoginLinkClicked {
    name: TrackingEventName.PAYWALL_LOGIN_LINK_CLICKED;
    params?: Record<string, unknown>;
}

interface PuchaseButtonClicked {
    name: TrackingEventName.PURCHASE_BUTTON_CLICKED;
    params: {
        trial_expired: boolean;
    };
}

interface ResultClicked {
    name: TrackingEventName.RESULT_CLICKED;
    params: {
        result_position: number;
        result_type: ModelType;
        search_context: string;
    };
}

interface SalesAppointmentButtonClicked {
    name: TrackingEventName.SALES_APPOINTMENT_BOOKING_BUTTON_CLICKED;
    params: {
        trial_expired: boolean;
    };
}

interface Search {
    name: TrackingEventName.SEARCH;
    params: {
        search_term: string;
    };
}

interface SideExtraContentClicked {
    name: TrackingEventName.SIDE_EXTRA_CONTENT_CLICKED;
    params: DocumentLink;
}

interface ThematicPlanAreaSelected {
    name: TrackingEventName.THEMATIC_PLAN_AREA_SELECTED;
    params: {
        tree_id: string;
        thematic_plan_view: 'treeView' | 'jurisFlow';
    };
}
interface ThematicPlanBusinessLawCategorySelected {
    name: TrackingEventName.THEMATIC_PLAN_BUSINESS_LAW_CATEGORY_SELECTED;
    params: {
        business_law_category: LawTheme;
        thematic_plan_view: 'treeView' | 'jurisFlow';
    };
}

interface ThematicPlanCollapseExpandAllButtonClicked {
    name: TrackingEventName.THEMATIC_PLAN_COLLAPSE_EXPAND_ALL_BUTTON_CLICKED;
    params: {
        direction: 'collapse' | 'expand';
    };
}
interface ThematicPlanNodeClicked {
    name: TrackingEventName.THEMATIC_PLAN_NODE_CLICKED;
    params: {
        node_id: string;
    };
}

interface ThematicPlanTableOfContentSearchSubmitted {
    name: TrackingEventName.THEMATIC_PLAN_TABLE_OF_CONTENT_SEARCH_SUBMITTED;
    params: {
        search_term: string;
    };
}
interface ThematicPlanTreeNodeCollapseExpandButtonClicked {
    name: TrackingEventName.THEMATIC_PLAN_TREE_NODE_COLLAPSE_EXPAND_BUTTON_CLICKED;
    params: {
        direction: 'collapse' | 'expand';
    };
}

export type DefinedEvents =
    | AdviceNoteContentConsumed
    | AnalysisResultClicked
    | AnalysisResultShowMoreClicked
    | ArticleExtraContentConsumed
    | BodyExtraContentClicked
    | CodearticleOfficialSourceClicked
    | ContentNavigationClicked
    | DecisionExtraContentConsumed
    | DocumentRead
    | EndOfTrialContactButtonClicked
    | FreemiumCardClicked
    | FreemiumModalOpened
    | HeaderQuickSearchClicked
    | HeaderNavigationClicked
    | IcicleRefined
    | LinkedAnalysisClicked
    | OfferMultipleLicencesButtonClicked
    | PageView
    | PaymentFailContactButtonClicked
    | PaymentFailRetryButtonClicked
    | PaymentSuccessBackButtonClicked
    | PaywallButtonClicked
    | PaywallLoginLinkClicked
    | PdfDownload
    | PuchaseButtonClicked
    | ResultClicked
    | SalesAppointmentButtonClicked
    | Search
    | SideExtraContentClicked
    | SignupSuccess
    | ThematicPlanAreaSelected
    | ThematicPlanBusinessLawCategorySelected
    | ThematicPlanCollapseExpandAllButtonClicked
    | ThematicPlanNodeClicked
    | ThematicPlanTableOfContentSearchSubmitted
    | ThematicPlanTreeNodeCollapseExpandButtonClicked;
