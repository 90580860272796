import { ModelType } from '@livv/models';
import { PaletteMode } from '@mui/material';
import { IndexUiState } from 'instantsearch.js';
import React from 'react';

export enum Attribute {
    CREATED_AT = 'createdAt',
    DATES = 'dates',
    KEYWORD_HIERARCHY = 'keywordHierarchy.lvl0',
    ISSUER = 'issuer',
    JURIDICTION_HIERARCHY = 'juridictionHierarchy.lvl0',
    LANGUAGE = 'language',
    LAW_SCOPES = 'lawScopes',
    LAW_TYPE = 'lawType',
    MAIN_KEYWORDS = 'mainKeywords',
    MODEL_TYPE = 'modelType',
    NATURE = 'nature',
    PATH_KEYWORDS = 'pathKeywords',
    SORT_BY_DATE = 'sortByDate',
    TREE_ID = 'treeId',
    TYPE = 'type',
    UPDATED_AT = 'updatedAt',
}

export enum AttributeType {
    HIGHLIGHT = 'highlight',
    PATH = 'path',
    TEXT = 'text',
}

export enum FilterType {
    HIERARCHICAL_MENU = 'hierarchicalMenu',
    REFINEMENT_DATE = 'refinementDate',
    REFINEMENT_LIST = 'refinementList',
}

export interface Filter {
    attributes: Attribute[];
    adminOnly?: boolean;
    defaultRefinement?: string[];
    facetOverrides?: string[];
    ignoreTranslation?: boolean;
    isSpecific?: boolean;
    searchable?: boolean;
    targetedModelTypes?: ModelType[];
    title?: string;
    type: FilterType;
}

export enum ModelAll {
    All = 'Tous',
}

export enum UIExtraContentType {
    BIBLIOGRAPHY = 'Bibliography',
}

export enum LawScopeType {
    FR = 'FR',
    EU = 'EU',
}

export type AllContentType = ModelType | UIExtraContentType;

export interface RefinementFilterProps {
    attribute: Attribute;
    filters: Filter[];
    title: string;
    value: ModelType[];
}

export enum Keywords {
    COMPETITION_FR = 'droit-de-la-concurrence',
    COMPETITION_EN = 'competition-law',
    DISTRIBUTION_FR = 'distribution',
    DISTRIBUTION_EN = 'distribution-law',
    CONSUMPTION_FR = 'consommation',
    CONSUMPTION_EN = 'french-consumer-law',
    BUSINESS_LAW_EN = 'european-business-law',
    BUSINESS_LAW_FR = 'droit-europeen-des-affaires',
}

export interface QuickSearchItem {
    Actions?: React.ReactNode;
    Icon: React.ReactNode;
    colorVersion?: PaletteMode;
    dataTestId?: string;
    href?: string;
    savedSearchQuery?: IndexUiState;
    subtitle?: string;
    title: string;
}
