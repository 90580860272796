const IGNORED_USER_AGENTS = ['Screaming Frog SEO Spider'];

const ENVIRONMENT =
    process.env.NEXT_PUBLIC_VERCEL_GITHUB_COMMIT_REF === 'master' ? 'production' : 'development';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const shouldIgnoreEvent = (userAgent) =>
    IGNORED_USER_AGENTS.some((ignoredAgent) => userAgent.includes(ignoredAgent));

module.exports = { ENVIRONMENT, SENTRY_DSN, shouldIgnoreEvent };
