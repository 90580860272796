import Button, { ButtonProps } from '@mui/material/Button';
import Link from 'next/link';
import React from 'react';
import { LinkProps } from '@utils/types/link';

interface ButtonLinkProps {
    openInNewTab?: boolean;
}

// following good practice from https://nextjs.org/docs/api-reference/next/link for SEO purpose
const ButtonLink = React.forwardRef<HTMLButtonElement, LinkProps<ButtonLinkProps & ButtonProps>>(
    ({ href, prefetch, children, openInNewTab, ...props }, ref) => {
        return (
            <Link href={href} prefetch={prefetch} target={openInNewTab ? '_blank' : undefined}>
                <Button ref={ref} {...props}>
                    {children}
                </Button>
            </Link>
        );
    },
);

export default ButtonLink;
